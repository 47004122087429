/**
 * OneBlock Client
 * @module oneblock-client.mjs
 * @version 1.0.06
 * @summary 28-11-2019
 * @author Mads Stoumann
 * @description Code for running OneBlocks client-side.
 */

/* Import Sections */
import * as Section from './oneblock-sections.mjs';

/* Import Widgets */
import * as Widget from './oneblock-widgets.mjs';

/* Import Common */
import { h } from './common.mjs';

/* Import helpers */
import { getOneBlockTrackingStrings, trackInteractionEvent } from './oneblock-tracking.mjs';

/* Import vimeo player to have full control of the video */
import Player from '../dependencies/player.es.js';


const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
const isEdge = '-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style && !window.navigator.msPointerEnabled;

/**
 * @function observeIntersections
 * @description Watches classes with transitions, triggers animation-play-state when in viewPort
 * @param {String} selector
 * TODO: Maybe narrow IO: if (entry.boundingClientRect.bottom >= entry.intersectionRect.bottom) { ... }
 */
function observeIntersections(elements) {
	const threshold = 0.5;
	/* Check if an array or `array of arrays` is empty */
	let isEmpty = a => {return Array.isArray(a) && a.every(isEmpty)};
	const io = new IntersectionObserver((entries) => {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				const ob = entry.target.ob;
				const animation = ob.animations[ob.index];
				if (animation.length > 0) {
					animation.forEach(item => {
						entry.target.classList.add(item);
					});
					ob.animations[ob.index] = [];
					/* If no animations left (for any breakpoint), unobserve */
					if (isEmpty(ob.animations)) {
						io.unobserve(entry.target);
					}
				}
			}
		},
		{
			threshold: [threshold]
		});
	});

	elements.forEach(ob => {
		io.observe(ob);
	});
}

/**
 * @function observeResize
 * @description Creates an `ob`-object per OneBlock, adds ResizeObserver, IntersectionObserver etc.
 * @param {String} selector
 * @param {Boolean} initClasses Set initial classes for elements with only one breakpoint
 */
export function observeResize(selector = 'c-ob', initClasses = false) {
	const elements = document.querySelectorAll(`.${selector}[data-ob-ps]`);
	let intersections = []
	/* Get current breakpoint-index for a given element */
	const getBreakpointIndex = (obj, width) => {
		let index = 0;
		if (width >= obj.max) {
			index = obj.last;
		}
		else if (width >= obj.min) {
			index = obj.breakpoints.findIndex(item => {return width < item}) - 1;
		}
		return index;
	}

	if (elements) {
		let ro = null;
		try {
			ro = new ResizeObserver( entries => {
				for (let entry of entries) {
					const width = entry.target.ob.useDeviceWidth ? window.innerWidth : entry.contentRect.width;
					const index = getBreakpointIndex(entry.target.ob, width);
					if (index !== entry.target.ob.index) {
						entry.target.ob.index = index;
						entry.target.className = `${selector} ${entry.target.ob.values[index]}`;
					}
				}
			});
		}
		catch {
			return;
		}

		elements.forEach(element => {
			try {
				const preset = JSON.parse(decodeURIComponent(element.dataset.obPs));
				const last = preset.breakpoints.length - 1;
				const max = preset.breakpoints[last];
				const min = preset.breakpoints[1] || 0;
				let useDeviceWidth = false;
				delete element.dataset.obPs;

				let animations = [];
				let hasAnimations = false;
				let values = [];

				preset.values.forEach((arr) => {
					let ani = [];
					let val = [];
					arr.forEach(item => {
						if (item.includes('-O-dw1')) {
							useDeviceWidth = true;
						}
						if (item.includes('E-ts')) {
							hasAnimations = true;
							ani.push(item.replace(/E-ts/g, 'ts'))
						}
						else {
							val.push(item)
						}
					})
					animations.push(ani);
					values.push(val.join(' '));
				})

				element.ob = {
					animations,
					breakpoints: preset.breakpoints,
					values,
					index: 0,
					last,
					max,
					min,
					useDeviceWidth,
					splash: preset.splash || {}
				}

				if (hasAnimations) {
					intersections.push(element);
				}

				if (last === 0 && initClasses) {
					element.className = `${selector} ${element.ob.values[0]}`
				}

				if (last > 0) {
					element.ob.index = -1;
					ro.observe(element);
				}
			} catch(err) {
				// eslint-disable-next-line
				console.error('OneBlock loaded without a default preset.')
			}
		});

		if ( intersections.length > 0) {
			observeIntersections(intersections);
		}
	}
}

/**
 * @function renderSplash
 * @description Client-side rendering of splashes
 */
export function renderSplash() {
	const defHorPos = 50;
	const splashes = document.querySelectorAll('.c-ob__splash');

	splashes.forEach(splash => {
		const parent = splash.closest('.c-ob');
		if (parent) {
			const file = splash.dataset.splashFile;
			const lines = splash.dataset.splashLines.split('|');

			if (parent.ob) {
			const data = parent.ob.splash;
			splash.innerHTML = `
			<svg viewBox="0 0 100 100">
				<use class="c-ob__splash-elm" xlink:href="${file}#${data.name}" />
				${lines.map((item, index) => {
		return `<text x="${data.content[index].x || defHorPos}" y="${data.content[index].y || defHorPos}" text-anchor="middle" class="c-ob__splash-line-${index + 1}">${item}</text>`
	}).join('')}
			</svg>`
			}
        }
	})
}

/**
 * @function renderSplash
 * @description Client-side intialization of simple splashes
 */
export function renderSimpleSplash() {
	const splashes = document.querySelectorAll('.c-ob__splash');

	splashes.forEach(splash => {
		splash.classList.remove('-hide');
	})
}

/**
 * @function renderSVGGradient
 * @param {Number} numOfGrad Number of gradients to generate
 * @description Helper function for dynamic SVG-gradients, renders dynamic stops
 */
export function renderSVGGradient(numOfGrad = 5) {
	const arr = Array.from({length: numOfGrad}, (v, i) => {return i});
	document.body.insertAdjacentHTML('beforeend', `
	<svg aria-hidden="true" style="position: absolute; width: 0; height: 0; overflow: hidden;" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
		<defs>
		${arr.map((item, index) =>  {return `
		<linearGradient id="svg-grad-${index+1}" x1="0%" x2="100%" y1="0%" y2="0%">
			<stop offset="0%" stop-color="var(--ob-color-SG-${index+1}A)"/>
			<stop offset="100%" stop-color="var(--ob-color-SG-${index+1}B)"/>
		</linearGradient>
		`}).join('')}
		</defs>
	</svg>
	`);
}

/**
 * @function setJSHooks
 * @param {String} selector
 * @description Adds eventListeners to OneBlock-links with data-js-hooks-attributes
 */
export function setJSHooks(selector = '[data-js]') {
	const elements = document.querySelectorAll(selector);
	if (elements) {
		elements.forEach(element => {
			/* Test if widgets have been loaded and if method exists */
			if (Widget[element.dataset.js]) {
				const func = Widget[element.dataset.js];
				const param = element.dataset.jsParam.split(',') || [];
				const event = element.dataset.jsEvent;
				if (func) {
					if (event) {
						switch(event) {
							case 'click':
								element.addEventListener('click', (evt) => {return func(evt, element, ...param)});
								break;
							case 'load':
								func(event, element, ...param);
								// window.addEventListener('DOMContentLoaded', (evt) => {return func(evt, element, ...param)});
								break;
							default: break;
						}
					}
					else {
						func(element, ...param);
					}
				}
			}
		});
	}
}

/**
 * @function setSnapSectionCounter
 * @description Method for setting snap-items-counter
 */
export function setSnapSectionCounter(scope = document) {
	if (isIE11) { return; }
	const io = new IntersectionObserver(entries => {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				const element = entry.target;
				const label = entry.target.__counterLabel;
				label.innerText = `${element.dataset.index}/${element.dataset.count}`;
			}
		}
		);
	},
	{
		threshold: 0.5
	});

	const sections = scope.querySelectorAll('.c-section--snap,.c-section--carousel');
	sections.forEach(section => {
		const counterLabel = section.querySelector('.c-section__counter');
		if (!counterLabel) { return; }
		const items = section.querySelectorAll('.c-section__item:not(.hidden)');
		const counterLength = items.length;
		items.forEach((item, index) => {
			item.__counterLabel = counterLabel;
			item.dataset.count = counterLength;
			item.dataset.index = index + 1;
			io.observe(item);
		});
	});
}

/**
 * @function setHiddenSectionItems for OneBlock
 * @description Method for hiding section items with appropriate layout
 */
export function setHiddenSectionItems(scope = document) {
	const sections = scope.querySelectorAll('.c-section');

	sections.forEach(section => {
		const
		     inner = section.querySelector('.c-section__inner'),
		     items = inner.querySelectorAll('.c-section__item');
		if (items.length) {
			items.forEach((item) => {
				if (item.querySelector('.-O-ly99') != null) {
					item.className += ' ' + 'hidden';
				}
				else {
				   item.classList.remove('hidden');
				}
			});
		}
	});
}

/**
 * @function setSnapSectionCarousel
 * @description Method for scrolling a snap-carousel-section
 */
export function setSnapSectionCarousel(scope = document) {
	let itemsPerPage = 4;
	const sections = scope.querySelectorAll('.c-section--carousel');

	sections.forEach(section => {
	    if (section.classList.contains('c-section--w-100')) { itemsPerPage = 1; }
		if (section.classList.contains('c-section--w-50')) { itemsPerPage = 2; }
		if (section.classList.contains('c-section--w-33')) { itemsPerPage = 3; }

		const label = section.querySelector('.c-section__page');
		if (!label) { return; }
		const inner = section.querySelector('.c-section__inner');
		const items = inner.querySelectorAll('.c-section__item:not(.hidden)');
		const count = items.length;
		if (count >= itemsPerPage) { section.classList.add('c-section--multipage'); }
		if (count <= itemsPerPage) { return; }

		const state = {
			page: 1,
			pages: Math.ceil(count / itemsPerPage)
		}

		const btnNext = section.querySelector('.c-section__nav-next') || h('button', { 'aria-label': 'Next', class: 'c-section__nav-next', type: 'button' });
		const btnPrev = section.querySelector('.c-section__nav-prev') || h('button', { 'aria-label': 'Previous', class: 'c-section__nav-prev', type: 'button' });

		/* Update current view */
		const update = () => {
			if (isIE11 || isEdge) {
				inner.scrollLeft = (state.page - 1) * inner.offsetWidth;
			}
			else {
				inner.scrollTo({ left: (state.page - 1) * inner.offsetWidth, behavior: 'smooth' });
			}
			label.innerText = `${state.page}/${state.pages}`;
			btnNext.toggleAttribute('disabled', state.page === state.pages);
			btnPrev.toggleAttribute('disabled', state.page === 1);
		}

		if (!section.hasAttribute('data-nav-created')) {
			section.dataset.navCreated = "1";

			const svg = (reverse = false) => { return `<svg viewBox="0 0 16 16"><path d="M9.707 13.707l5-5c0.391-0.39 0.391-1.024 0-1.414l-5-5c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l3.293 3.293h-9.586c-0.552 0-1 0.448-1 1s0.448 1 1 1h9.586l-3.293 3.293c-0.195 0.195-0.293 0.451-0.293 0.707s0.098 0.512 0.293 0.707c0.391 0.391 1.024 0.391 1.414 0z" ${reverse ? `transform="scale(-1, 1) translate(-16, 0)"` : ''}></path></svg>` }

			btnNext.addEventListener('click', () => {
				state.page++;
				if (state.page > state.pages) { state.page = state.pages }
				update();
			});

			btnPrev.addEventListener('click', () => {
				state.page--;
				if (state.page < 1) {state.page = 1; }
				update();
			});

			btnNext.innerHTML = svg();
			btnPrev.innerHTML = svg(true);
			label.parentNode.insertBefore(btnPrev, label);
			label.parentNode.insertBefore(btnNext, label.nextElementSibling);
		}
		update();
	});
}

/**
 * @function setSectionTypes
 * @param {String} selector
 * @description Adds Section Types (carousels, tabs etc.) for OneBlock
 */
export function setSectionTypes(selector = '[data-section-type]') {
	const sections = document.querySelectorAll(selector);
	sections.forEach(section => {
		if (Section[section.dataset.sectionType]) {
			new Section[section.dataset.sectionType](section, section.dataset);
		}
	})
	//Set link with tooltip
		const linksWithTooltip = [...document.querySelectorAll('.c-ob__text *:not(nav) a:not([href])')];
		linksWithTooltip.forEach(link => {
			link.setAttribute('data-title', link.title);
			link.setAttribute('title', '');
			link.setAttribute('tabindex', '0');
		});
}

document.addEventListener('lazybeforeunveil', ({ target }) => {
    if(target.dataset.src && target.tagName === 'IFRAME') {
       target.src = target.dataset.src;
    }
});

/**
 * @function setVideoUrl that depends on CookieBot Marketing Permissions for OneBlock
 * @description Method to replace the videoUrl that detects if video is tracked or not
 */
export function setVideoUrl(scope = document) {
	const externalVideos = scope.querySelectorAll('.c-ob__bg-iframe');

	externalVideos.forEach(video => {
	  const isVimeo = video.dataset.isvimeo === 'True' ? true : false;
	  let videoUrl = video.dataset.src;
	  if (!window.marketingPermissions) {
		 if (!isVimeo && !videoUrl.includes('youtube-nocookie')) {
			videoUrl = videoUrl.replace('youtube', 'youtube-nocookie');
		 }
		 else {
			const searchParam = 'dnt=true';
			if (videoUrl.includes('?')) {
				videoUrl += '&' + searchParam;
			}
			else {
				videoUrl += '?' + searchParam;
			}
		 }
		 video.dataset.src = videoUrl;
	   }
	});
}

/**
 * @function setVideoPlay
 * @param {String} selector
 * @description Adds click-handlers for video-overlays
 */
export function setVideoPlay(selector = '.c-ob__bg-play', hideClass = 'c-ob__bg-video') {
	function loadScript() {
		const tag = document.createElement('script');
		tag.src = "https://www.youtube.com/iframe_api";

      	const firstScriptTag = document.getElementsByTagName('script')[0];
      	firstScriptTag?.parentNode.insertBefore(tag, firstScriptTag);
	}
	loadScript();

	function onPlayerReady(event) {
		event.target.playVideo();
	}

	function onYouTubeIframeAPIReady(video) {
		new YT.Player(video, {
			events: {
				'onReady': onPlayerReady,
			}
	  });
	}

	const overlays = document.querySelectorAll(selector);
	overlays.forEach(overlay => {return overlay.addEventListener('click', (event) => {

		let wrapper = event.target.parentNode;
		if (event.target.className.includes('--bottom')) {
			wrapper = event.target.parentNode.parentNode.parentNode;
		}

		const video = wrapper.querySelector(`[itemprop="video"]`);
		const videoType = video.tagName === 'VIDEO' ? 1 : 2;

		wrapper.classList.add(hideClass);

		/* <video> */
		if (videoType === 1) {
			video.play();
			video.addEventListener('ended', function videoEnded() {
				wrapper.classList.remove(hideClass);
				video.removeEventListener('ended', videoEnded);
			});
		}
		else {
			/* <iframe> */
			let delimiter = '?';
			if (/\?/.test(video.src)) {
				delimiter = '&'
			}

			if (video.src.includes('youtube.com')) {
				video.src = video.src += delimiter + 'enablejsapi=1';
				onYouTubeIframeAPIReady(video);

				return;
			}

			if (video.src.includes('vimeo.com')) {
				const player = new Player(video);
				player.play();

				return;
			}

			video.src = video.dataset.src += delimiter + 'autoplay=1';
		}
	})});
}

/**
 * @function initTracking
 * @description Sets up link tracking for the OneBlock
 */
export function initTracking() {
	let obas = [...document.querySelectorAll('.c-ob a, .c-ob [data-js-event]')];
	obas.forEach((oba) => {
		oba.addEventListener('click', (e) => {
			let a = e.target;
			let ob = a.closest('.c-ob');
			let trackingStrings = getOneBlockTrackingStrings(ob);
			trackInteractionEvent(trackingStrings.pageType, `${trackingStrings.allHeadlines} ${trackingStrings.culture} ${trackingStrings.pathname}`, trackingStrings.visitorID);
		})
	});
}

/**
 * @function setVideoFallback
 * @description Display an image fallback when there's no playable video source and fake a cover effect if no object fit support is available
 */
export function setVideoFallback() {
	const obVideos = document.querySelectorAll(".c-ob video[itemprop='video']");
	const noObjectFitSupport = 'objectFit' in document.documentElement.style === false;

	obVideos.forEach(obVideo => {
		const imageFallback = obVideo.dataset.imageFallback;
		// If there's an image fallback specified against the video
		if (imageFallback) {
			// Check the network state of the video to figure out if we need to apply a fallback
			addOrRemoveVideoFallbackBasedOnNetworkState(obVideo, imageFallback);

			// Set up an event listener to detect if the video changes and reapply fallback logic
			// We're using durationchange as it fires earlier than other events like loadeddata or loadedmetadata
			obVideo.addEventListener("durationchange", (e) => addOrRemoveVideoFallbackBasedOnNetworkState(e.target, imageFallback));
		}

		// If there's no object fit support add a class for fallback styling
		if (noObjectFitSupport) {
			obVideo.classList.add("c-ob__bg--ie-cover")
		}
	});
}

/**
 * @function addOrRemoveVideoFallbackBasedOnNetworkState
 * @description Checks the network state of a video and either adds or removes the video fallback
 * @param video The video element to test against
 */
export function addOrRemoveVideoFallbackBasedOnNetworkState(video, imageFallback) {
	const addFallback = video.networkState === 3;
	const videoContainer = video.parentNode;
	const fallbackClass = "c-ob__bg-wrapper--video-fallback";
	const backgroundImage = `url(${imageFallback})`;

	if (addFallback) {
		videoContainer.classList.add(fallbackClass);
		videoContainer.style.backgroundImage = backgroundImage;
	} else {
		videoContainer.classList.remove(fallbackClass);
		videoContainer.style.backgroundImage = "";
	}
}