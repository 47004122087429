/**
 * OneBlock Templates
 * @module oneblock-templates.mjs
 * @version 1.0.04
 * @summary 02-12-2019
 * @author Mads Stoumann
 * @description Templates for OneBlock elements
 */

import * as Section from './oneblock-templates-sections.mjs';

/**
 * @function mergeSplashData
 * @param {Object} splash
 * @param {Object} preset
 * @description Merge splash-data with splash-data from preset
 */
export function mergeSplashData(splash, preset) {
	const content = [...splash.content.map((item, i) => {
		return {
			...item,
			...preset.content[i]
		};
	})];
	return { content, file: splash.file, name: preset.name };
}

/**
 * @function renderAll
 * @param {Object} data
 * @description Renders all sections and OneBlocks from json
 */
export function renderAll(data) {
	return data.map(section => {
		return tmplSection(section);
	}).join('');
}

/**
 * @function tmplLink
 * @param {Object} data
 * @param {Boolean} editMode
 * @param {Boolean} hasVideo
 * @description Renders inline link-text, with optional url-wrapper, if video url exists and video is present.
 */
function tmplLink(data, editMode, hasVideo) {
	return `
		${data.url && hasVideo && !editMode ? `<a class="c-ob__link-inline" href="${data.url}" itemprop="url">` : ''}
			<span class="c-ob__link-text" ${data.jsHook ? `data-js="${data.jsHook}" data-js-event="${data.jsHookEvent || ''}" data-js-param="${data.jsHookParam || ''}"` : ``}${editMode ? ` data-ob-group="link"`: ''}>${data.linkText}</span>
		${data.url && hasVideo && !editMode ? `</a>` : ''}
	`
}

/**
 * @function tmplMedia
 * @param {Object} data
 * @param {Boolean} editMode
 * @description A solid background-color/gradient can be set, so both image and background-image is optional. However, if neither caption or splash are present as well, the element won't render. In production, add `srcset` to image and bgImage
 */
function tmplMedia(data, editMode) {
	const hasSplash = data.splash && data.splash.content || false;
	const hasVideo = data.video && data.video.src || false;
	const dataLabel = !hasVideo && data.bgImage && data.bgImageAltTextAsLabel && data.bgImageAltText || data.image && data.imageAltTextAsLabel &&  data.imageAltText ?  "data-label=" + data.bgImageAltText || data.imageAltText + "" : "";
	const multipleImagesClass = data.image && data.imageTwo ? 'c-ob__multiple-images' : '';

	return (data.bgImage || data.image || data.imageCaption || data.splash || hasVideo) ?  `
	<figure class="c-ob__bg-wrapper ${multipleImagesClass}" itemscope itemtype="${hasVideo ? 'https://schema.org/VideoObject' : 'https://schema.org/ImageObject'}" ${dataLabel}>
		${hasVideo ? tmplVideo(data, editMode) : ''}
		${hasVideo && data.bgImage && !data.video.overlay ? '' : data.bgImage ? `${data.imageZoom ? '<div class="c-ob__modal"><label class="c-ob__zoomin-image_label"></label>' : ''}<img title="${data.imageCaption}" class="c-ob__bg ${hasVideo ? 'c-ob__bg-poster' : ''}" itemprop="image" src="${data.bgImage}" loading="lazy"${editMode ? ` data-ob-group="bgmedia"`: ''} data-obj-fit="true" />${data.imageZoom ? '</div>' : ''}` : ``}
		${hasVideo && data.video.overlay ? `<div class="c-ob__bg-play c-ob__bg-play--center"><svg viewBox="0 0 24 24"><path d="M3.9,18.9V5.1c0-1.6,1.7-2.6,3-1.8l12,6.9c1.4,0.8,1.4,2.9,0,3.7l-12,6.9C5.6,21.5,3.9,20.5,3.9,18.9z"></path></svg></div>`: ''}
		${data.image ? `${data.imageZoom ? '<div class="c-ob__modal"><label class="c-ob__zoomin-image_label"></label>' : ''}<img alt="${data.headline || 'ALT-TEXT MISSING'}" class="c-ob__img" itemprop="image" src="${data.image}" loading="lazy"${editMode ? ` data-ob-group="image"`: ''} />${data.imageZoom ? '</div>' : ''}` : ``}
		${data.imageTwo ? `<img alt="${data.imageTwoAltText || 'ALT-TEXT MISSING'}" class="c-ob__img" itemprop="image" src="${data.imageTwo}" loading="lazy"${editMode ? ` data-ob-group="image"`: ''} />` : ``}
		${hasSplash ? tmplSplash(data, editMode) : ``}
		${data.imageCaption ? `<figcaption class="c-ob__bg-caption" itemprop="caption"${editMode ? ` data-ob-group="imagecaption"`: ''}>
			${data.imageCaption}
		</figcaption>`:''}
	</figure>` : ``;
}

/**
 * @function tmplMeta
 * @param {Object} data
 * @param {Boolean} editMode
 * @description Renders meta-section
 */
function tmplMeta(data, editMode) {
	return `
	<nav class="c-ob__meta"${editMode ? ` data-ob-group="meta"`: ''}>
	${data.map(item =>
	{return `<${editMode ? 'span' : 'a'} class="c-ob__meta-item" href="${item.link}" target="_blank" rel="noopener noreferrer" itemprop="potentialAction" content="https://schema.org/ShareAction"${editMode ? ` data-ob-group="meta"`: ''}>
			<svg class="c-ob__meta-icon"><use xlink:href="${item.icon}"></use></svg>
			<span class="c-ob__meta-text" itemprop="commentCount">${item.text}</span>
		</${editMode ? 'span' : 'a'}>`}).join('')}
	</nav>`;
}

/**
 * @function tmplOneBlock
 * @param {Object} data
 * @param {Boolean} editMode
 * @description Main rendering template for OneBlock
 */
export function tmplOneBlock(data, editMode = false) {
	const hasVideo = data.video && data.video.src || false;
	const headerTag = data.headerTag ? data.headerTag : 'h2';
	return `
	<div class="c-ob" data-ob-ps="${encodeURIComponent(JSON.stringify(data.preset))}" itemscope itemtype="https://schema.org/CreativeWork">
		${tmplMedia(data, editMode)}
		<section class="c-ob__content">
			${data.tagline && !data.logoImage? `<div class="c-ob__tagline"${editMode ? ` data-ob-group="tagline"`: ''}>${data.tagline}</div>`: (data.logoImage ? `<div class="c-content-card__info"><div><span>${data.tagline}</span></div><img src="${data.logoImage}" alt="" /></div>` : '')}
			${data.headline ? `<${headerTag} class="c-ob__headline" itemprop="headline"${editMode ? ` data-ob-group="headline"`: ''}>${data.headline}</${headerTag}>`: ``}
			${data.description ? `<div class="c-ob__text" itemprop="description"${editMode ? ` data-ob-group="text"`: ''}>
				${data.description}
				${data.linkText ? `<div class="c-ob__dummy-button-offset"><div class="c-ob__link-text-holder">${data.linkText ? tmplLink(data, editMode) : ``}</div></div>` : ''}
			</div>` : ''}
			${hasVideo && data.video.overlay ? `<div class="c-ob__bg-play c-ob__bg-play--bottom c-ob__bg-play--round">
				<svg viewBox="0 0 24 24"><path d="M3.9,18.9V5.1c0-1.6,1.7-2.6,3-1.8l12,6.9c1.4,0.8,1.4,2.9,0,3.7l-12,6.9C5.6,21.5,3.9,20.5,3.9,18.9z"></path></svg>
				</div>`: ''}
			${data.meta.length ? tmplMeta(data.meta, editMode) : ``}
			${data.linkText && !data.ratingNumber ? `<div class="c-ob__link-text-holder">${tmplLink(data, editMode)}</div>` : (data.ratingNumber ? `<div class="c-content-card__blocks"><span>${data.ratingLabel} ${data.ratingNumber} ${data.ratingText}</span><span class="c-content-card__readmore c-ob__link-text">${data.url ? data.linkText : ''}</span></div>` : '')}
		</section>
		${data.url && !hasVideo && !editMode ? tmplUrl(data) : ``}
	</div>`;
}

/**
 * @function tmplSection
 * @param {Object} data
 * @description Main template for Sections, wrapping OneBlocks
 */
function tmplSection(data) {
	return `
	<section class="${data.section}">
		<div class="grid__row">
			${data.type ? Section[data.type](data) :
		data.content.map((item) => {return item.displayOption ?
			`<div class="${item.displayOption}">${tmplOneBlock(item)}</div>` :
			tmplOneBlock(item)}).join('')
}
		</div>
	</section>`
}

/**
 * @function tmplSplash
 * @param {Object} data
 * @param {Boolean} editMode
 * @description Renders a splash and `x` amount of `<text>`-elements
 */
function tmplSplash(data, editMode) {
	const splash = data.splash.content.map(item => {return item.text}).join('|');
	const splashData = mergeSplashData(data.splash, data.preset.splash); /* Only for editMode */

	return splashData.content.length > 0 ? `
	<div class="c-ob__splash c-ob__splash--simple">
		${splashData.content.map((item, index) => {
			return `${item.text}`
		}).join('')}
	</div>` : '';

// return splashData.content.length > 0 ? `
	// <div class="c-ob__splash"${editMode ? ` data-ob-group="splash"`: `data-splash-lines="${splash}" data-splash-file="${data.splash.file}"`}>
	// 	${editMode ?
	// 	`<svg viewBox="0 0 100 100">
	// 			<use class="c-ob__splash-elm" xlink:href="${splashData.file}#${splashData.name}" />
	// 			${splashData.content.map((item, index) => {
	// 				return `<text x="${item.x || 50}" y="${item.y || 50}" text-anchor="middle" class="c-ob__splash-line-${index + 1}">${item.text}</text>`
	// 			}).join('')}
	// 		</svg>` : ''}
	// </div>`: '';
}

/**
 * @function tmplUrl
 * @param {Object} data
 * @description Renders url and link-text
 */
function tmplUrl(data) {
	   return `<a class="c-ob__link" href="${data.url}" itemprop="url">${data.linkText || 'LINK-TEXT MISSING'}</a>`;
}

/**
 * @function tmplVideo
 * @param {Object} data
 * @param {Boolean} editMode
 * @description Renders a background-video, either locally hosted or iframe-based
 */
function tmplVideo(data, editMode) {
	return `
		<meta itemprop="description" content="${data.description}">
		<meta itemprop="name" content="${data.headline}">
		<meta itemprop="thumbnailUrl" content="${data.bgImage}">
		<meta itemprop="uploadDate" content="${data.video.date}"></meta>
		${data.video.type === 2 ?
		`<iframe class="c-ob__bg" itemprop="video" src="${data.video.src}${data.video.autoPlay ? '?autoplay=1' : ''}" frameborder="0" tabindex="-1" allow="autoplay; encrypted-media" allowfullscreen="allowfullscreen"${editMode ? ` data-ob-group="bgmedia"`: ''}></iframe>` :
		`<video class="c-ob__bg" itemprop="video" src="${data.video.src}"${data.bgImage ? ` poster="${data.bgImage}"`:''}${data.video.autoPlay ? ` autoplay`:''}${data.video.controls ? ` controls`:''}${data.video.loop ? ` loop` : ''}${data.video.muted ? ` muted`:''}${editMode ? ` data-ob-group="bgmedia"`: ''}>
			<track
				label="${data.video.videoTextLabel}"
				kind="subtitles"
				srclang="${data.video.lang}"
				src="${data.video.videoTextTracks}"
			default>
		</video>`
}
	`;
}