/* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
/**
 * Carousel module.
 * @module carousel.mjs
 * @version 0.9.49
 * @summary 14-10-2019
 * @author Mads Stoumann
 * @description Carousel-control
 */

/* import tracking tools */
import { trackInteractionEvent, getVisitorID, getCulture, getPageType } from './oneblock-tracking.mjs';
import Timeout from './timeout.mjs';

export class Carousel {
	constructor(wrapper, settings) {
		this.settings = Object.assign(
			{
				animateTimeout: 33,
				navNext: 'M12.062 15.867c-.191.191-.502.191-.693 0l-.277-.278c-.192-.191-.192-.501 0-.693l4.062-4.063H2.99c-.27 0-.49-.22-.49-.49v-.392c0-.27.22-.49.49-.49l12.165-.001-4.063-4.064c-.192-.191-.192-.502 0-.693l.277-.278c.191-.191.502-.191.693 0l5.234 5.234.015.015.051.06-.066-.075c.029.029.054.06.077.091.08.112.127.249.127.397 0 .172-.063.33-.168.45l-5.27 5.27z',
				navNextSize: 22,
				autoplay: false,
				autoplayDelay: 3000,
				autoplayCSSProp: '--carousel-delay',
				breakpoints: [600, 1000, 1400, 1920, 3840],
				enablePopup: false,
				eventSlideSet: '',
				eventSlideChange: '',
				renderIndicators: true,
				renderNav: true,
				renderNavInline: true,
				renderThumbnails: false,
				renderThumbnailsNav: true,
				pageItems: [2, 3, 4, 6, 8],
				slideDir: '',
				slides: [],
				thumbnails: [],
				touchDistance: 100,
				videoThumbFallback: '/images/06.jpeg',
				controlsVerticalPosition: 16,
				controlsHorizontalPosition: 16,
				controlsSwitchBreakpoint: 1200,

				clsActive: 'c-carousel__item--active',
				clsAirplay: 'c-carousel__nav-airplay',
				clsAnimate: 'c-carousel--animate',
				clsCarousel: 'c-carousel__list',
				clsInner: 'c-carousel__inner',
				clsItem: 'c-carousel__item',
				clsItemContent: 'c-carousel__item-content',
				clsItemHeading: 'c-carousel__item-heading',
				clsItemImage: 'c-carousel__item-image',
				clsItemLink: 'c-carousel__item-link',
				clsItemText: 'c-carousel__item-text',
				clsItemVideo: 'c-carousel__item-video',
				clsIndicator: 'c-carousel__indicator',
				clsIndicatorFirst: 'c-carousel__indicator-item--first',
				clsIndicatorItem: 'c-carousel__indicator-item',
				clsIndicatorButton: 'c-carousel__indicator-btn',
				clsIndicatorButtonActive: 'c-carousel__indicator-btn--active',
				clsIndicatorWrapper: 'c-carousel__indicator-wrapper',
				clsLive: 'c-carousel__live',
				clsLoading: 'c-carousel--loading',
				clsNav: 'c-carousel__nav',
				clsNavNext: 'c-carousel__nav-next',
				clsNavPause: 'c-carousel__nav-pause',
				clsNavPlay: 'c-carousel__nav-play',
				clsNavPopup: 'c-carousel__nav-popup',
				clsNavPopupClose: 'c-carousel__nav-popup--close',
				clsNavPrev: 'c-carousel__nav-prev',
				clsPopup: 'c-carousel--popup',
				clsReverse: 'c-carousel--reverse',
				clsSingleSlide: 'c-carousel--single',
				clsThumbnailImage: 'c-carousel__thumb-image',
				clsThumbnailInner: 'c-carousel__thumb-inner',
				clsThumbnailItem: 'c-carousel__thumb-item',
				clsThumbnailNav: 'c-carousel__thumb-nav',
				clsThumbnailNext: 'c-carousel__thumb-next',
				clsThumbnailOuter: 'c-carousel__thumb-outer',
				clsThumbnailPrev: 'c-carousel__thumb-prev',
				clsThumbnailActive: 'c-carousel__thumb-item--active',
				clsThumbnailWrapper: 'c-carousel__thumb-wrapper',

				labelNext: 'Next',
				labelPlay: 'Play/Pause',
				labelPopup: 'Open full-screen',
				labelPrev: 'Previous',

				showTimeBar: true,
				timebarClass: 'c-carousel__timebar',
				clsAnimation: 'c-carousel__item--animation',
				clsButton: 'c-ob__link-text',
				clsLink: 'c-ob__link',
				clsDelay: '-O-ly17',
				clsContent: 'c-ob',
				isFirefox: navigator.userAgent.toLowerCase().indexOf('firefox') > -1,
				asParentLinked: false
			},
			this.stringToType(settings)
		);
		this.init(wrapper);
	}

	/**
	 * @function addAirplaySupport
	 * @description Adds support for Apple airplay for videos
	 */
	addAirplaySupport() {
		if (window.WebKitPlaybackTargetAvailabilityEvent) {
			const videos = this.wrapper.querySelectorAll('video');
			videos.forEach(video => {
				const button = document.createElement('button');
				button.classList.add(this.settings.clsAirplay);
				video.parentNode.insertBefore(button, video.nextSibling);
				video.addEventListener(
					'webkitplaybacktargetavailabilitychanged',
					function(event) {
						switch (event.availability) {
							case 'available':
								button.hidden = false;
								button.disabled = false;
								break;
							case 'not-available':
								button.hidden = true;
								button.disabled = true;
								break;
							default:
								break;
						}
					}
				);
				if (window.WebKitPlaybackTargetAvailabilityEvent) {
					button.addEventListener('click', function() {
						video.webkitShowPlaybackTargetPicker();
					});
				}
			});
		}
	}

	/**
	 * @function autoPlay
	 * @param {Boolean} run
	 * @description Starts/stops autoplay of slides
	 */
	autoPlay(run) {
		this.isPlaying = run;
		if (this.play) {
			this.play.setAttribute('aria-pressed', run);
			this.play.classList.toggle(this.settings.clsNavPause, run);
		}
		if (run) {
			this.interval = new Intrvl(() => {
				this.navSlide(true);
			}, this.settings.autoplayDelay)
		}
	}

	/**
	 * @function createNavigation
	 * @description Create navigation-elements: indication, next, play, prev
	 */
	createNavigation() {
		/* Create navigation: next, prev, play/pause */
		if (this.settings.renderNav && this.slides.length > 1) {
			const previous = this.h('button', { class: this.settings.clsNavPrev, 'aria-label': this.settings.labelPrev, rel: 'next' });
			previous.insertAdjacentHTML('afterbegin', this.navArrow(true));
			previous.addEventListener('click', () => {
				if (this.settings.autoplay) {
					this.interval.clear();
				}
				return this.navSlide(false)});

			const next = this.h('button', {	class: this.settings.clsNavNext, 'aria-label': this.settings.labelNext, rel: 'prev' });
			next.insertAdjacentHTML('afterbegin', this.navArrow(false));
			next.addEventListener('click', () => {
				if (this.settings.autoplay) {
					this.interval.clear();
				}
				return this.navSlide(true)
			});

			if (this.settings.renderNavInline) {
				this.inner.appendChild(previous);
				this.inner.appendChild(next);
			}
			else {
				this.navigation.appendChild(previous);
				this.navigation.appendChild(next);
				this.wrapper.appendChild(this.navigation);
			}
		}

		/* Create indicators */
		if (this.settings.renderIndicators && this.slides.length > 1) {
			this.indicators = this.h('nav', { class: this.settings.clsIndicator, itemscope: 'itemscope', itemtype: 'http://schema.org/SiteNavigationElement'});
			this.indicators.innerHTML = this.slides.map((item, index) =>
			{return `<div class="${this.settings.clsIndicatorItem}">
				<button class="${this.settings.clsIndicatorButton}" aria-label="${this.headings[index] ? this.headings[index].innerText : ''}" data-slide="${index}" itemprop="name"></button>
			</div>`}).join('');

			this.indicators.addEventListener('click', event => {
				const slide = event.target.dataset.slide;
				if (slide) {
					const index = parseInt(slide, 0);
					this.gotoSlide(index, index > this.activeSlide);
				}
			});
			this.indicatorsWrapper = this.h('div', {
				class: this.settings.clsIndicatorWrapper
			});
			this.indicatorsWrapper.appendChild(this.indicators);
			this.wrapper.appendChild(this.indicatorsWrapper);
		}

		/* Add play/pasue button if autoPlay is enabled */
		if (this.settings.autoplay) {
			this.play = this.h('button', { class: this.settings.clsNavPlay, 'aria-label': this.settings.labelPlay });
			this.play.addEventListener('click', () => {return this.autoPlay(!this.isPlaying)});
			this.inner.appendChild(this.play);
		}

		/* Add support for popup, if enabled */
		if (this.settings.enablePopup) {
			this.popup = this.h('button', { class: this.settings.clsNavPopup, 'aria-label': this.settings.labelPopup });
			this.popup.addEventListener('click', () => { this.wrapper.classList.toggle(this.settings.clsPopup); this.popup.classList.toggle(this.settings.clsNavPopupClose); });
			this.inner.appendChild(this.popup);
		}
	}

	/**
	 * @function createSlides
	 * @param {Array} json
	 * @description Create slides from simple (images only) or advanced json (see readme.md)
	 */
	createSlides(json) {
		return json.map((slide, index) => {return `
		<li class="${this.settings.clsItem}" itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
			<figure class="${this.settings.clsItemContent}" itemprop="associatedMedia" itemscope itemtype="${slide.type && slide.type === 'video' ? 'http://schema.org/VideoObject' : 'http://schema.org/ImageObject'}">
			${slide.type && slide.type === 'video' ? `
				${slide.videoType === '1' ?
				`<video class="${this.settings.clsItemVideo}" tabindex="-1" controls ${slide.thumbnail ? `poster="${slide.thumbnail}"` : ''} preload="metadata"><source src="${slide.src}" type="video/${slide.videoType || 'mp4'}"></source></video>` :
				`<iframe src="${slide.src}" class="${this.settings.clsItemVideo}" frameborder="0" tabindex="-1" loading="lazy" allow="autoplay; encrypted-media" allowfullscreen></iframe>`
				}`:
				`<img src="${slide.src}" alt="${slide.title}" class="${this.settings.clsItemImage}" loading="${index === 0 ? 'eager' : 'lazy'}" data-obj-fit />`
			}
			${this.settings.clsItemHeading ? `<span class="${this.settings.clsItemHeading}" itemprop="name">${slide.title}</span>` : ''}
			${this.settings.clsItemText ? `<figcaption class="${this.settings.clsItemText}" itemprop="description">${slide.description}</figcaption>` : ''}
			</figure>
		</li>`}).join('');
	}

	/**
	 * @function createSlideWrappers
	 * @param {Array} arr
	 * @description Create wrappers for slide-items in custom formats
	 */
	createSlideWrappers(arr) {
		return arr.map((slide) => {return `<li class="${this.settings.clsItem}" itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">${slide.outerHTML}</li>`}).join('')
	}

	/**
	 * @function createThumbnails
	 * @description Create thumbnails and thumbnail-navigation
	 */
	createThumbnails() {
		this.thumbnailNext = this.h('button', { class: this.settings.clsThumbnailNext, 'aria-label': this.settings.labelNext });
		this.thumbnailNext.insertAdjacentHTML('afterbegin', this.navArrow(false));
		this.thumbnailNext.addEventListener('click', () => { this.gotoPage(true);	});

		this.thumbnailPrev = this.h('button', { class: this.settings.clsThumbnailPrev, 'aria-label': this.settings.labelPrev });
		this.thumbnailPrev.insertAdjacentHTML('afterbegin', this.navArrow(true));
		this.thumbnailPrev.addEventListener('click', () => { this.gotoPage(false); });

		this.thumbnailInner = this.h('div', { class: this.settings.clsThumbnailInner });

		this.thumbnailInner.innerHTML = this.thumbnails.map((image, index) => {return `
		<figure class="${this.settings.clsThumbnailItem}">
			<img src="${image.src}" alt="${image.alt}" class="${this.settings.clsThumbnailImage}" data-slide="${index}" loading="lazy" />
		</figure>`}).join('');

		const thumbnailOuter = this.h('div', { class: this.settings.clsThumbnailOuter	});
		const thumbnailWrapper = this.h('nav', { class: this.settings.clsThumbnailWrapper	});

		thumbnailOuter.appendChild(this.thumbnailInner);
		if (this.settings.renderThumbnailsNav && this.slides.length > 1) {
			thumbnailWrapper.appendChild(this.thumbnailPrev);
		}
		thumbnailWrapper.appendChild(thumbnailOuter);
		if (this.settings.renderThumbnailsNav && this.slides.length > 1) {
			thumbnailWrapper.appendChild(this.thumbnailNext);
		}
		this.wrapper.appendChild(thumbnailWrapper);

		this.thumbnails = this.thumbnailInner.querySelectorAll(`.${this.settings.clsThumbnailItem}`);

		/* Add eventListeners */
		this.thumbnailInner.addEventListener('click', event => {
			const slide = event.target.dataset.slide;
			if (slide) {
				this.gotoSlide(slide - 0);
				// track
				let trackingValue = '';
				const carousel = event.target.closest('.c-carousel');
				if (carousel) {
					trackingValue = carousel.dataset.trackingValue;
				}
				trackInteractionEvent(getPageType(), `thumbnail interaction ${trackingValue} ${getCulture()} ${location.pathname}`, getVisitorID());
			}
		});
	}

	/**
	 * @function getIndex
	 * @param {Number} slide
	 * @param {Number} length Total number of slides
	 * @param {Boolean} dirUp Direction: up (true) or down (false)
	 * @description Return index of next/prev slide
	 * @returns Number
	 */
	getIndex(slide, length, dirUp) {
		let index = slide;

		if (dirUp) {
			index++;
		} else {
			index--;
		}

		if (index < 0) {
			index = length;
		}
		if (index > length) {
			index = 0;
		}
		return index;
	}


	/**
	 * @function getVideoThumbnail
	 * @description Gets / creates video-thumbnail
	 * @param {Node} elm
	 */
	getVideoThumbnail(elm, fallBack) {
		const vimeoJSON = async (src) => {
			try {
				const data = await (await fetch(`//vimeo.com/api/oembed.json?url=${encodeURIComponent(src)}`)).json();
				return { src: data.thumbnail_url };
			}
			catch(err) {
				return fallBack;
			}
		}
		const extractFrame = (video) => {
			const canvas = document.createElement('canvas');
			return new Promise(resolve => {
				video.onseeked = () => {
					const ctx = canvas.getContext('2d');
					ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
					resolve(canvas.toDataURL());
				};
				video.currentTime = 0.1;
			});
		}

		return new Promise(async resolve => {
			if (elm.src.includes('mp4')) {
				const video = document.createElement('video');
				video.onloadedmetadata = () => {
					extractFrame(video).then(response => {
						resolve(response ? { src: response } : fallBack);
					});
				};
				video.src = elm.src;
			}
			else if (elm.src.includes('vimeo')) {
				const json = await vimeoJSON(elm.src);
				resolve(json);
			}
			else if (elm.src.includes('youtube')) {
				const videoID = elm.src.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop();
				resolve(videoID ? { src:`//img.youtube.com/vi/${videoID}/0.jpg`} : fallBack);
			}
		});
	}

	/**
	 * @function gotoPage
	 * @param {Boolean} [dirUp]
	 * @description Scroll to a specific "page" within timeline or thumbnails
	 */
	gotoPage(dirUp) {
		if (typeof dirUp !== 'undefined') {
			const page = this.page + (dirUp ? 1 : -1);
			this.page = page > this.totalPages ? 1 : page < 1 ? this.totalPages : page;
		}
		this.thumbnailInner.style.transform = `translateZ(0) translateX(${-100 * (this.page - 1)}%)`;
	}

	/**
	 * @function gotoSlide
	 * @param {Number} slideIndex
	 * @param {Boolean} [dirUp]
	 * @param {Boolean} [animate]
	 * @description Go to specific slide
	 */
	gotoSlide(slideIndex = -1, dirUp, animate = true) {
		if (!this.slides[slideIndex]) { return; }

		/* Determine slide-direction */
		const slideDir = this.settings.slideDir !== '' ? this.settings.slideDir : dirUp;
		this.carousel.classList.toggle(
			this.settings.clsReverse, !slideDir
		);
		this.previousSlide = this.activeSlide;

		if (slideIndex > -1) {
			this.activeSlide = slideIndex - 0;
		}

		this.setReference();
		this.reorderSlides();

		/* Animate: Remove class, add it again after 50ms */
		if (animate && !this.settings.asParentLinked) {
			this.carousel.classList.remove(this.settings.clsAnimate);
			Timeout.set(() => {
				this.carousel.classList.add(this.settings.clsAnimate);
			}, this.settings.animateTimeout);
		}

		if (this.settings.renderIndicators) {
			this.setActiveIndicator();
		}

		this.setLiveRegion();

		this.page = Math.ceil((slideIndex + 1) / this.itemsPerPage);

		if (this.settings.renderThumbnails) {
			this.setActiveThumbnail();
			this.gotoPage();
		}

		if (this.settings.eventSlideChange) {
			this.wrapper.dispatchEvent(new CustomEvent(this.settings.eventSlideChange, { detail: { index: slideIndex, dir: dirUp } }));
		}
	}

	/**
	 * @function h
	 * @param {String} type
	 * @param {Array | Object} attributes
	 * @param {Array} [children]
	 * @description DOM-factory
	 */
	h(type, attributes, children = []) {
		const element = document.createElement(type);
		for (let key in attributes) {
			element.setAttribute(key, attributes[key]);
		}
		if (children.length) {
			children.forEach(child => {
				if (typeof child === 'string') {
					element.appendChild(document.createTextNode(child));
				} else {
					element.appendChild(child);
				}
			});
		}
		return element;
	}

	/**
	 * @function handleKeys
	 * @param {Event} event
	 * @description Handle keyboard-navigation
	 */
	handleKeys(event) {
		switch (event.key) {
			/* Space: Pause autoplay */
			case ' ':
				if (document.activeElement === this.wrapper) {
					this.isPlaying = !this.isPlaying;
					this.play.focus();
				}
				break;

			/* Left: Previous slide */
			case 'ArrowLeft':
			case 'Left':
				this.navSlide(false);
				break;

			/* Right: Next slide */
			case 'ArrowRight':
			case 'Right':
				this.navSlide(true);
				break;
			default:
				break;
		}
	}

	/**
	 * @function handleTouch
	 * @description Handles touch-move events
	 * @param {Event} event
	 * @param {Number} distance Distance to swipe before callback-function is invoked
	 */
	handleTouch(event, distance) {
		const currentPosition = event.changedTouches[0].pageX;
		if (currentPosition < this.touchPosition - distance) {
			this.touchPosition = currentPosition;
			this.navSlide(true);
		} else if (currentPosition > this.touchPosition + distance) {
			this.touchPosition = currentPosition;
			this.navSlide(false);
		}
	}

	/**
	 * @function init
	 * @description Set initial elements, create slides
	 */
	init(wrapper) {
		let json, slides,
		thisObj = this;

		/* Set defaults */
		this.activeSlide = 0;
		this.interval = '';
		this.isPlaying = this.settings.autoplay;
		this.itemsPerPage = 4;
		this.page = 1;
		this.previousSlide = 0;
		this.total = 1;
		this.totalPages = 1;
		this.touchPosition = 0;

		/* Set outer wrapper, add eventListeners */
		this.wrapper = wrapper;
		this.wrapper.addEventListener('keydown', event => {return this.handleKeys(event)});
		this.wrapper.addEventListener('touchmove', event => {return this.handleTouch(event, this.settings.touchDistance)}, { passive: true });
		this.wrapper.addEventListener('touchstart', event => { this.touchPosition = event.changedTouches[0].pageX; }, { passive: true });

		/* Get headings, if exists, otherwise set to empty array */
		this.headings = wrapper.querySelectorAll(`.${this.settings.clsItemHeading}`) || [];
		
		/* Set navigation element */
		if (!this.settings.renderNavInline) {
			this.navigation = this.h('nav', { class: this.settings.clsNav, itemscope: 'itemscope', itemtype: 'http://schema.org/SiteNavigationElement'});
		}

		/* Set inner wrapper, create if not exists */
		this.inner = this.wrapper.querySelector(`.${this.settings.clsInner}`);
		if (!this.inner) {
			/* Markup not in correct structure, create slides from direct childNodes or this.settings.slides-array */
			if (this.settings.slides.length) {
				json = typeof this.settings.slides === 'string' ? JSON.parse(this.settings.slides) : this.settings.slides;
			}
			slides = json ? this.createSlides(json) : this.createSlideWrappers(Array.from(this.wrapper.children));
			this.wrapper.innerHTML = '';
			this.inner = this.h('div', { class: this.settings.clsInner });
			this.wrapper.appendChild(this.inner);
		}

		/* Set carousel (list-wrapper), create if not exists */
		this.carousel = this.wrapper.querySelector(`.${this.settings.clsCarousel}`);
		if (!this.carousel) {
			this.carousel = this.h('ul', {
				class: this.settings.clsCarousel,
				itemscope: 'itemscope',
				itemtype: 'http://schema.org/ItemList'
			});
			this.inner.appendChild(this.carousel);
		}
		if (slides) { this.carousel.innerHTML = slides; }
		this.carousel.classList.add(this.settings.clsAnimate);

		/* Create live-region for A11y-feedback */
		this.live = this.h('span', {
			class: this.settings.clsLive,
			'aria-atomic': true,
			'aria-live': true
		});
		if (this.navigation) {
			this.navigation.appendChild(this.live);
		} else {
			this.inner.appendChild(this.live);
		}

		this.setSlides();

		/* If only a single slide exists */
		if (this.slides.length === 1) {
			this.carousel.classList.add(this.settings.clsSingleSlide);
		}

		this.createNavigation();

		/* Create thumbnails, fetch video-frames as thumbnails */
		if (this.settings.renderThumbnails) {
			let promises = [];

			if (this.settings.thumbnails.length) {
				promises = this.settings.thumbnails;
			}
			else {
				this.slides.map(element => {
						const thumb = element.querySelector('iframe, img, video');
						return thumb.tagName.toLowerCase() === 'img' ? promises.push(thumb) : promises.push(this.getVideoThumbnail(thumb, { src: this.settings.videoThumbFallback }));
					}
				);
			}

			Promise.all(promises).then(results => {
				this.thumbnails = results;
				/* Create match-media-listeners for breakpoint-changes */
				if (this.thumbnails.length) {
					this.breakpoints = this.settings.breakpoints.map((breakpoint, index) => {
							const min = index > 0 ? this.settings.breakpoints[index - 1] : 0;
							return window.matchMedia(`(min-width: ${min}px) and (max-width: ${breakpoint - 1}px)`);
						}
					);
					this.breakpoints.forEach(breakpoint =>
						{return breakpoint.addListener(this.updateItemsPerPage.bind(this))}
					);

					this.createThumbnails();
					this.updateItemsPerPage();
					this.gotoSlide(this.activeSlide, true, false);
				}
			});
		}
		else {
			this.gotoSlide(this.activeSlide, true, false);
		}

		/* Handle external slideSet-event */
		if (this.settings.eventSlideSet) {
			this.wrapper.addEventListener(this.settings.eventSlideSet, (event) => {return this.gotoSlide(event.detail.index, event.detail.dir)});
		}

		/* Update CSS Custom Property with Animation Timing */
		document.documentElement.style.setProperty(this.settings.autoplayCSSProp, `${this.settings.autoplayDelay}ms`);

		/* Enable Apple AirPlay for <video> */
		this.addAirplaySupport();

		/* Remove loading class */
		this.wrapper.classList.remove(this.settings.clsLoading);

		/* Run Autoplay */
		this.autoPlay(this.isPlaying);

		/* Link multiple carousels */
		this.linkCarousels(document);

		/* Initial controls position */
		this.setControlsPosition();

		/* Change controls position on mobile resize */
		window.addEventListener('resize', this.setControlsPosition.bind(this), true);

		/* Timebar creation */
		this.timebarAdd();

		/* Initial timebar movement */
		this.moveTimebar();

		/* When clicking button click on link */
		this.wrapper.querySelectorAll(`.${this.settings.clsButton}`).forEach(b => {
			b.addEventListener('click', ev => {
				ev.target.closest(`.${this.settings.clsContent}`).querySelector(`.${this.settings.clsLink}`).click();
			})
		})

		/* Hover listening */
		if (this.settings.autoplay) {
			this.wrapper.querySelectorAll(`.${this.settings.clsContent}`).forEach(c => {
				c.addEventListener('mouseover', (ev => {
					thisObj.interval.pause();
					this.stopTimebar();
				}).bind(thisObj));
				c.addEventListener('mouseout', (ev => {
					thisObj.interval.resume();
					this.moveTimebar(true);
				}).bind(thisObj));
			})
		}

		this.settings.asParentLinked = !!document.querySelectorAll(`[data-event-slide-set="${this.wrapper.dataset.eventSlideChange}"]`).length;
	}

	/**
	 * @function stopTimebar
	 * @description stops timebar moving
	 */
	stopTimebar() {
		let elem = document.querySelector(`.${this.settings.timebarClass}`);
		elem.hidden = true;
	}

	/**
	 * @function linkCarousels
	 * @param {Node} scope
	 * @description Links two carousels
	 */
	linkCarousels(scope) {
		const masterId = this.settings.eventSlideChange;
		if (!masterId) {
			return;
		}
		/* Look for elements where "data-event-slide-set" matches masterId */
		const slaves = [...scope.querySelectorAll(`[data-event-slide-set='${masterId}']`)];
		this.wrapper.addEventListener(masterId, (event) => {
			const index = event.detail.index - 0;
			const dir = event.detail.dir;
			slaves.forEach((slave) => {
				slave.dispatchEvent(new CustomEvent(masterId, { detail: { index, dir } }));
			});
		});
	}

	/**
	 * @function navArrow
	 * @param {Boolean} [reverse] Flip
	 * @description returns a right (or left) navigation arrow
	 */
	navArrow(reverse = false) {
		return `<svg viewBox="0 0 ${this.settings.navNextSize} ${this.settings.navNextSize}"><path d="${this.settings.navNext}" ${reverse ? `transform="scale(-.9, 1) translate(-${this.settings.navNextSize}, 3)"` : 'transform="translate(1, 0)"'}></path></svg>`
	}

	/**
	 * @function navSlide
	 * @param {Boolean} [dirUp] dirUp Direction: up (true) or down (false)
	 * @description Switch to slides with/without timeout
	 */
	navSlide(dirUp = true) {
		const content = this.slides[this.activeSlide].querySelector(`.${this.settings.clsContent}`);
		
		document.querySelector(`.${this.settings.clsActive}`).classList.remove(this.settings.clsAnimation);

		if (content && content.classList.contains(this.settings.clsDelay)) {
			Timeout.set(() => {
				this.moveTimebar();
				let index = this.getIndex(this.activeSlide, this.total, dirUp);
				this.gotoSlide(index, dirUp);
				this.setSlideMarker(index);
				this.setControlsPosition();
			}, 1000)
		} else {
			this.moveTimebar();
			let index = this.getIndex(this.activeSlide, this.total, dirUp);
			this.gotoSlide(index, dirUp);
			this.setSlideMarker(index);
			this.setControlsPosition();
		}
	}

	/**
	 * @function navSlideFunctionality
	 * @param {Boolean} [dirUp] dirUp Direction: up (true) or down (false)
	 * @description Go to next or previous slide
	 */
	 navSlideFunctionality(dirUp = true) {
		document.querySelector(`.${this.settings.clsActive}`).classList.remove(this.settings.clsAnimation);
		Timeout.set(() => {
			this.moveTimebar();
			let index = this.getIndex(this.activeSlide, this.total, dirUp);
			this.gotoSlide(index, dirUp);
			this.setSlideMarker(index);
			this.setControlsPosition();
		}, 1000)
	}

	/**
	 * @function moveTimebar
	 * @description Dinamic width calculation to show progress bar
	 */
	moveTimebar(restart = false) {
		if (!this.settings.autoplay) {
			return;
		}

		if (this.tmbr && this.tmbr.stop) {
			this.tmbr.stop();
		}

		let elem = document.querySelector(`.${this.settings.timebarClass}`);
		let width = 0;
		let textAnimationDelay = 2000;
		const increaser = this.settings.isFirefox ? .25 : .1;
		const timeout = (this.settings.autoplayDelay - textAnimationDelay) / 1000;
		elem.style.width = 0;

		this.tmbr = Timeout.set(() => {
			elem.hidden = false;
			if (this.settings.timebarIntrval) {
				this.settings.timebarIntrval.clear();
			}
			this.settings.timebarIntrval = new Intrvl(() => {
				if (width < 100) {
					width = width + increaser;
					elem.style.width = width + "%";
				} else {
					elem.style.width = 0;
				}
			}, timeout);
		}, textAnimationDelay);
	}

	/**
	 * @function setSlideMarker
	 * @param {Number} [index] current slide index
	 * @description Adding specific wrapper attribute to style prev next buttons
	 */
	setSlideMarker(index) {
		this.wrapper.setAttribute('data-slide-index', (this.total + 1) == (index + 1) ? 0 : (index + 1))
	}

	/**
	 * @function setControlsPosition
	 * @description Calculating and setting position of slider navigation container
	 */
	 setControlsPosition() {
		const controls = this.wrapper.querySelector(`.${this.settings.clsNav}`) ? this.wrapper.querySelector(`.${this.settings.clsNav}`) : null;
		const figure = this.wrapper.querySelector(`.${this.settings.clsActive} figure`);
		if (!controls || !figure) {
			return;
		}
		controls.style.cssText = `right: ${this.settings.controlsHorizontalPosition}px;
			top: ${figure.offsetHeight - this.settings.controlsVerticalPosition}px;`;
	}

	/**
	 * @function timebarAdd
	 * @description Adding block which shows switching next slide progress
	 */
	timebarAdd() {
		if (this.settings.showTimeBar) {
			const timebarWrap = this.h('div', { class: 'c-carousel__timebar-wrapper' });
			const timebar = this.h('div', { class: 'c-carousel__timebar' });

			timebarWrap.appendChild(timebar);
			this.inner.appendChild(timebarWrap);
		}
	}

	/**
	 * @function reorderSlides
	 * @description Recalculate flex-order, set aria-hidden
	 */
	reorderSlides() {
		this.slides.forEach((slide, index) => {
			let order;

			if (index === this.activeSlide) {
				slide.classList.add(this.settings.clsActive);
				slide.classList.add(this.settings.clsAnimation);
			} else {
				slide.classList.remove(this.settings.clsActive);
				slide.classList.remove(this.settings.clsAnimation);
			}
			slide.toggleAttribute('aria-hidden', index !== this.activeSlide);

			if (index < this.reference) {
				order = this.total - (this.reference - index) + 1;
			} else if (index > this.reference) {
				order = index - this.reference;
			} else {
				order = 0;
			}
			slide.style.order = order + 1;
		});
	}

	/**
	 * @function setActiveIndicator
	 * @description Set active dot, remove active from previous
	 */
	setActiveIndicator() {
		try {
			this.indicators.children[this.previousSlide].classList.remove(
				this.settings.clsIndicatorButtonActive
			);
			this.indicators.children[this.activeSlide].classList.add(
				this.settings.clsIndicatorButtonActive
			);
			this.indicators.children[0].classList.toggle(this.settings.clsIndicatorFirst, this.activeSlide === this.slides.length -1)
		}
		catch(err) {}
	}

	/**
	 * @function setActiveThumbnail
	 * @description Set active dot, remove active from previous
	 */
	setActiveThumbnail() {
		this.thumbnails[this.previousSlide].classList.remove(
			this.settings.clsThumbnailActive
		);
		this.thumbnails[this.activeSlide].classList.add(
			this.settings.clsThumbnailActive
		);
	}

	/**
	 * @function setLiveRegion
	 * @description Updates aria-live-area
	 */
	setLiveRegion() {
		this.live.innerText = `${this.activeSlide + 1} / ${this.total + 1}`;
	}

	/**
	 * @function setReference
	 * @param {Number} [slide]
	 * @description Set reference slide (previous or last)
	 */
	setReference(slide = this.activeSlide) {
		this.reference = slide - 1 < 0 ? this.total : slide - 1;
		this.slides[this.reference].style.order = 1;
	}

	/**
	 * @function setSlides
	 * @description Inits slides, set attributes etc.
	 */
	setSlides() {
	this.slides = Array.from(this.carousel.children);
		/* Add meta:position, add aria-hidden to non-active slides */
		this.slides.forEach((slide, index) => {
			const wrapper = slide.firstElementChild;
			if (!wrapper.classList.contains(this.settings.clsItemContent)) {
				wrapper.classList.add(this.settings.clsItemContent);
			}
			this.h('meta', { itemprop: 'position', content: index + 1 }, slide);
			if (index !== this.activeSlide) {
				slide.setAttribute('aria-hidden', true);
			}
		});
		this.total = this.slides.length - 1;
	}

	/**
	 * @function stringToType
	 * @param {Object} obj
	 * @description Convert data-attribute value to type-specific value
	 */
	stringToType(obj) {
		const object = Object.assign({}, obj);
		Object.keys(object).forEach(key => {
			if (typeof object[key] === 'string' && object[key].charAt(0) === ':') {
				object[key] = JSON.parse(object[key].slice(1));
			}
		});
		return object;
	}

	/**
	 * @function updateItemsPerPage
	 * @description Updates items per page on matchMedia-match
	 */
	updateItemsPerPage() {
		this.breakpoints.forEach((breakpoint, index) => {
			if (breakpoint.matches) {
				this.itemsPerPage = this.settings.pageItems[index];
			}
		});
		this.totalPages = Math.ceil(this.total / this.itemsPerPage);
		this.page = Math.ceil((this.activeSlide + 1) / this.itemsPerPage);
		this.thumbnailNext.hidden = this.totalPages === 1;
		this.thumbnailPrev.hidden = this.totalPages === 1;
		this.gotoPage();
	}
}

function Intrvl(callback, delay) {
	let callbackStartTime
	let remaining = 0

	this.timerId = null
	this.paused = false

	this.pause = () => {
		this.clear()
		remaining -= Date.now() - callbackStartTime
		this.paused = true
	}
	this.resume = () => {
		window.setTimeout(this.setTimeout.bind(this), remaining)
		this.paused = false
	}
	this.setTimeout = () => {
		this.clear()
		this.timerId = window.setInterval(() => {
		callbackStartTime = Date.now()
		callback()
		}, delay)
	}
	this.clear = () => {
		window.clearInterval(this.timerId)
	}
	this.setTimeout()
}