/**
 * OneBlock Templates: Sections
 * @module oneblock-client.mjs
 * @version 1.0.02
 * @summary 16-10-2019
 * @author Mads Stoumann
 * @description Templates for Custom Section types
 */
import { tmplOneBlock } from './oneblock-templates.mjs';

/**
 * @function tmplSectionCarousel
 * @param {Object} data
 * @description Wraps OneBlocks in a carousel
 */	
export function tmplSectionCarousel(data) {
	return `
	<div class="c-carousel c-carousel--indicator-lines c-carousel--nav-below" data-section-type="carousel" data-render-nav-inline=":false">
		${data.content.map((item) => {return `<div class="c-carousel__item-content">${tmplOneBlock(item)}</div>`}).join('')}
	</div>`
}

/**
 * @function tmplSectionSnap
 * @param {Object} data
 * @description Wraps OneBlocks in a snap-points-slider
 */	
export function tmplSectionSnap(data) {
	return `
	<div class="c-snap c-snap--preview" data-section-type="snap">
		${data.content.map((item) => { return `<div class="c-snap__item">${tmplOneBlock(item)}</div>`}).join('')}
	</div>`
}